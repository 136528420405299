import React, { Component } from 'react';
import Home from './HomeBanner';
import IndexTours from './IndexTours';
import db from '../db/tours.json';

class Index extends Component {
  render() {
    const { match: { params } } = this.props;
    const logo = params.logo === 'true';
    const price = params.price === 'true';
    return (
      <React.Fragment>
        <Home
          logo={logo}
          price={price}
          locale={params.locale}
        />
        <IndexTours
          logo={logo}
          price={price}
          locale={params.locale}
          tours={db[params.locale].tours}
        />
      </React.Fragment>
    )
  }
}

export default Index;