import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class IndexTours extends Component {
  render() {
    const { locale, logo, price } = this.props;
    return (
      <div className="index-tours">
        <div className="index-tours-table">
          <div>
            <div className="index-tours-container">
              <div className="index-title">
                <div>
                  {locale === 'en' ? 'Index': 'Índice'}
                </div>
              </div>
              <div>
                <ul>
                 {
                   this.props.tours.map((tour, index) => (
                    <li key={index}>
                      <Link to={`/tour/${locale}/${logo}/${tour.slug}/${price}`}>
                        <div>
                          {tour.name}
                          <img src="/resources/img/white-arrow.png" alt="white-arrow" />
                        </div>
                      </Link>
                    </li>
                   ))
                 }
                </ul>
              </div>
              <div className="index-tours-footer">
                 <div className="index-tours-footer-icons">
                  <div>
                    <img src="/resources/img/palmera-icon.png" alt="" />
                  </div>
                  <div>
                    <img src="/resources/img/snorkel-icon.png" alt="" />
                  </div>
                  <div>
                    <img src="/resources/img/ballena-icon.png" alt="" />
                  </div>
                  <div>
                    <img src="/resources/img/solyolas-icon.png" alt="" />
                  </div>
                 </div>
                 <div>
                  <div className="index-tours-footer-arrow-right">
                    <Link to={`/tour/${locale}/${logo}/${this.props.tours[0].slug}/${price}`}>
                      <img src="/resources/img/white-arrow.png" alt="" />
                    </Link>
                  </div>
                 </div>
              </div>
            </div>
          </div>
          <div>
            <div className="index-tours-thumbnail">
            </div>
          </div>
        </div>
      </div>
    )
  }
}
