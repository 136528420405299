import React from 'react';
import './css/App.css';
import './css/normalize.css';
import './css/pages.css';
import './css/responsive.css'
import './css/fonts.css';
import './css/icons.css';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Index from './pages/Index';
import Tour from './pages/Tour';

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/home/:locale/:logo/:price" component={Index} />
      <Route exact path="/tour/:locale/:logo/:slug/:price" component={Tour} />
      <Redirect to="/home/es/true/false" />
    </Switch>
  </Router>
);

export default App;
