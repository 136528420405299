import React, {Component} from 'react';
import db from '../db/tours.json';
import {Link} from 'react-router-dom';
import Video from './VideoContainer';
import BackgroundVideo from './BackgroundVideo';
import Loading from './Loading';

class Tour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      windowWidth: window.innerWidth
    };
  }
  componentWillReceiveProps(nextProps) {
    const currentParams = this.getParams(this.props);
    const nextParams = this.getParams(nextProps);
    if (currentParams.slug !== nextParams.slug) {
      this.setState({isReady: false});
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }
  getParams(props) {
    const { match: { params } } = props;
    return params;
  }
  render() {
    const {isReady,windowWidth} = this.state;
    const params = this.getParams(this.props);
    const {
      locale,
      slug,
      logo,
      price: priceParam,
    } = params;
    const showPrice = priceParam === 'true';
    const tours = db[locale].tours;
    const index = tours.findIndex(tour => tour.slug === slug);
    const {
      name,
      activities,
      duration,
      title,
      subtitle,
      pickups,
      banner, 
      name_brackground,
      pickups_titles,
      pickups_seasons,
      name_width,
      video_id,
      price,
      no_opacity,
    } = tours[index];
    const lists = [];
    const size = 6;
    const nextIndex = index + 1 > tours.length - 1 ? 0 : index + 1;
    const nextActivity = tours[nextIndex];
    const newActivities = [...activities];
    while (newActivities.length > 0)
      lists.push(newActivities.splice(0, size));

    const isMobile = windowWidth < 800;

    return (
      <div className="tour-detail">
        <div className="tour-detail-container">
          {
            (!isReady && video_id && !isMobile) && <Loading />
          }
          {!isReady &&
            <div
              style={{backgroundImage: `url('/resources/img/${banner}.jpg')`}}
              className="tour-detail-banner"
            >
              {
                (isMobile && video_id) &&
                <Video
                  video_id={video_id}
                />
              }
            </div>  
          }
          {
            (video_id && !isMobile) && <BackgroundVideo
              id={video_id}
              onReady={(e) => {
                this.setState({isReady: true});
              }}
            />
          }
          
          <div className={`tour-detail-width-container tour-detail-width-${name_width}`}>
            <div
              style={{backgroundImage: `url('/resources/img/brochazo-${name_brackground}.png')`}}
              className="tour-detail-name"
            >
              {name}
            </div>
            <div>
              <div className="tour-detail-duration">
                {duration} hrs.
              </div>
            </div>
          </div>

          <div className={`tour-detail-information ${(isReady && !no_opacity)  && 'opacity-information'}`}>
          <div>
            <div className="tour-detail-title">
              <div className="tour-detail-title-compound">
                <div>{title}</div>
                <div>{subtitle}</div>
              </div>
              <div className="background-olita"></div>
            </div>
            {
              showPrice &&
              <div className="tour-detail-price">
                {price}
              </div>
            }
          </div>
          <div className="activities-pickups">
            <div className="activities">
              {
                lists.map((list, i) => (
                  <ul key={i}>
                    {
                      list.map((activity, index) => (
                        <li key={index}>
                          <span className="icon-check" />
                          <label  dangerouslySetInnerHTML={{ __html: activity}} />
                        </li>
                      ))
                    }
                  </ul>
                ))
              }
            </div>
            <div className="pickups">
              <div>
                <table>
                  <thead>
                    <tr>
                      <td >Pick ups</td>
                      {
                        pickups_titles && pickups_titles.map(title => (
                          <td dangerouslySetInnerHTML={{__html: title}} />
                        ))
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      pickups_seasons ?
                      <tr>
                        {
                          pickups_seasons.map((season, index) => (
                            <td key={index}>
                              <table>
                                <thead>
                                  <tr>
                                    <td className="season">{season}:</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    pickups.map((pickup, i) => (
                                      <tr key={i}>
                                        <td>{pickup.name}</td>
                                        <td>{pickup.times[index]}</td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            </td>
                          ))
                        }
                      </tr>
                      :
                      pickups.map((pickup, index) => (
                        <tr key={index}>
                          <td>{pickup.name}</td>
                          {
                            pickup.times.map((time, i) => (
                              <td key={i}>{time}</td>
                            ))
                          }
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="buttons">
              <div>
                  <Link to={`/home/${locale}/${logo}/${priceParam}`}>
                    <img src="/resources/img/casita-indice.png" alt="" />
                  </Link>
                  <Link to={`/tour/${locale}/${logo}/${nextActivity.slug}/${priceParam}`}>
                    <img src="/resources/img/next.png" alt="" />
                  </Link>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

export default Tour;