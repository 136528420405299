import React, { Component } from 'react';
import ReactPlayer from 'react-player';

export default class VideoContainer extends Component {
  render() {
    return (
      <ReactPlayer
        url={`https://youtu.be/${this.props.video_id}`}
        playing={true}
        loop={true}
        width="100%"
        height="100%"
        onPlay={this.props.onReady}
        // controls={true}
        config={{
          youtube: {
            modestbranding: 1,
          }
        }}
      />
    );
  }
}