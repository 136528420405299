import React, { Component } from 'react';
import YoutubeBackground from 'react-youtube-background';

export default class BackgroundVideo extends Component {
  render() {
    const {id,onReady} = this.props;
    return (
      <YoutubeBackground
        className="youtube-background"
        videoId={id}
        onPlay={onReady}
      />
    );
  }
}
