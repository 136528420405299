import React from 'react'

const Home = (props) => (
  <section>
    <div className="banner-home-hero">
      <div className="centered-in-banner">
        {
          props.logo ?
          (
            <img src="/resources/img/logo-esperanzas.png" alt="Esperanza's Tours Logo" />
          ) :
          (
            <div className="no-logo-title">
              {props.locale === 'en' ? 'Land and Sea Adventure' : 'Actividades Acuáticas y Terrestres'}
            </div>
          )
        }
      </div>
      <div className="logos">
        
        <div>
          <img className="logo-certs" src="/resources/img/safe-travels.png" alt="Safe travels" />
        </div>
        <div>
          <img className="logo-certs" src="/resources/img/sello-punto-limpio.jpeg" alt="Punto limpio" />
        </div>
      </div>
    </div>
  </section> 
);

export default Home;
